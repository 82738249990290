<template>
  <section class="invoice-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(addUpdateItemAction)"
        @reset.prevent="resetForm"
        v-if="accountingfileData != null"
      >
        <b-row style="margin-bottom: 20px">
          <b-col cols="8">
            <h6
              class="section-label text-primary float-left"
              style="
                baccountingfile-right: 5px solid orange;
                baccountingfile-bottom: 1px solid orange;
                baccountingfile-radius: 0px 50px 0px 0px;
                font-size: 18px;
                padding-right: 10px;
                top: 1px;
                width: auto;
              "
            >
              <feather-icon icon="FileAddIcon" size="25" />
              {{ operationTitle }} Fichier: <strong> {{ accountingfileData.name }} </strong>
            </h6>
          </b-col>

          <b-col cols="4">
            <b-button
              v-if="$router.name == 'accountingfile-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'accountingfile-view',
                  params: { id: accountingfileData.id + 1 },
                })
              "
            >
              <span class="d-none d-sm-inline">Suiv.</span>
              <feather-icon icon="ChevronRightIcon" size="12" />
            </b-button>
            <b-button
              v-if="$router.name == 'accountingfile-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'accountingfile-view',
                  params: { id: accountingfileData.id - 1 },
                })
              "
            >
              <feather-icon icon="ChevronLeftIcon" size="12" />
              <span class="d-none d-sm-inline">Préc.</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'accountingfile-list',
                })
              "
            >
              <feather-icon icon="ListIcon" size="12" />
              <span class="d-none d-sm-inline">Liste Fichiers Comptables</span>
            </b-button>
          </b-col>
        </b-row>

          <section class="invoice-add-wrapper">
       
          
            <!-- dudget -->
        <b-card no-body class="border mt-1">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="InfoIcon" size="18" />
              <span class="align-middle ml-50">Parametres du Budget / Axe Budgetaire</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-form>
      <b-row>
        <b-col cols="12" md="3">
          <b-form-group label="" label-for="budgetType" label-class="mb-1">
            <b-form-radio-group
              class="ml-1 list item-view-radio-group"
              button-variant="outline-primary"
              id="budgetType"
              v-model="accountingfileData.budget.budgetType"
              :options="budgetComptabilityOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
          </b-card-body>
        </b-card>

        <!-- accounting -->
        <b-card no-body class="border mt-1">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="DollarSignIcon" size="18" />
              <span class="align-middle ml-50">Monnaie de comptabilité</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
           <b-form class="mt-2">
      <b-row>
        <b-col sm="4">
          <b-form-group label="Monnaie de compabilité" label-for="currency">
            <b-form-input
              v-model="accountingfileData.currency.currency"
              placeholder="USD"
              name="currency" 
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group label="Symbole de Monnaie" label-for="currency_symbol">
            <b-form-input
              v-model="accountingfileData.currency.currency_symbol"
              placeholder="$"
              name="currency_symbol" 
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group label="Format des montants" label-for="amount_format">
            <b-form-input
              v-model="accountingfileData.currency.amount_format"
              placeholder="#.## | #,##"
              name="amount_format"
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group
            label="Nombre des decimaux"
            label-for="amount_format_decimal"
          >
            <b-form-input
              v-model="accountingfileData.currency.amount_format_decimal"
              placeholder="2"
              name="amount_format_decimal"
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group
            label="Séparateur des miliers"
            label-for="mile_separator"
          >
            <b-form-input
              v-model="accountingfileData.currency.mile_separator"
              placeholder="Ex: . , Vide = séparer par espacement"
              name="mile_separator"
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group
            label="Format de la quantité"
            label-for="quantity_format"
          >
            <b-form-input
              v-model="accountingfileData.currency.quantity_format"
              placeholder="#.## | #,##"
              name="quantity_format"
            />
          </b-form-group>
        </b-col> 
      </b-row>
    </b-form>
          </b-card-body>
        </b-card>

        <!-- advanced -->
        <b-card no-body class="border mt-1">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="SettingsIcon" size="18" />
              <span class="align-middle ml-50">Parametres Avancés</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-form>
      <b-row>
        <b-col sm="4">
          <b-form-group
            label="Longueur d'un Compte Général"
            label-for="length_account_gen"
          >
            <b-form-input
              v-model="accountingfileData.advanced.length_account_gen"
              placeholder="15"
              name="length_account_gen"
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group
            label="Longueur d'un Compte Analytique"
            label-for="length_account_analytic"
          >
            <b-form-input
              v-model="accountingfileData.advanced.length_account_analytic"
              placeholder="15"
              name="length_account_analytic"
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group label="Repport à nouveau" label-for="repport_to_new">
            <v-select
              v-model="accountingfileData.advanced.repport_to_new"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="repportNewOptions"
              :reduce="(val) => val.value"
              input-id="repport_to_new"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
          </b-card-body>
        </b-card>

        </section>  

        <b-row style="margin-bottom: 20px" v-if="showed == true">
          <b-col cols="12">
            <b-button
              ref="refSubmitEl"
              type="submit"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon icon="SaveIcon" v-if="loading == false" size="19" />

              {{ actionName }}
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              @click="reinitForm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Annuler
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="margin-bottom: 20px" v-if="accountingfileData == null">
      <b-col cols="12">
        <h1>Chargement des données en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
// accountingfiles
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BMedia,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BAvatar,
  BFormFile,
  BMediaAside,
  BTable,
  BCardHeader,
  BCardTitle,
  // Form Validation
  BFormInvalidFeedback,
  BFormDatepicker,
  BMediaBody,
  BImg,
  BFormRadioGroup,
} from "bootstrap-vue";
//

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "./storeModule";
import router from "@/router";

const moduleName = "accountingfile";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    BFormDatepicker,
    Logo,
    BMedia,
    BMediaBody,
    BFormFile,
    BMediaAside,
    BImg,
    BAvatar,
    BTable,
    BCardHeader,
    BCardTitle, 

    BFormRadioGroup,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      actionIndex: 0,
      showed: true,
      loading: false,
      bankOptions: [],  
      // budget
      budgetType: "",
      budgetComptabilityOptions: [
        { text: "Axe Analytique", value: "analytique" },
        { text: "Axe Générale", value: "generale" },
        { text: "Aucune", value: "" },
      ],
      // currency
      currency: "USD",
      currency_symbol: "$",
      amount_format: "",
      amount_format_decimal: "2",
      quantity_format: "",
      mile_separator: "",
      // advanced
      length_account_gen: "",
      length_account_analytic: "",
      repport_to_new: "",
      repportNewOptions: [
        { label: "Solde", value: "solde" },
        { label: "Détail", value: "detail" },
        { label: "Aucun", value: "aucun" },
      ],

    };
  },
  props: {
    accountingfileData: {
      type: Object,
    },
    defaultaccountingfileData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  async mounted() {

    if (router.currentRoute.name == moduleName + "-param") {
      this.sku_accountingfile = router.currentRoute.params.id;
      this.operationTitle = "Parametrage du ";
      this.operationIcon = "ToolIcon";
      this.imageActionText = "Parametrage";

      this.actionName = "Enregistrer les paramètres";
      this.imageActionText = "Parametrage";
      this.actionIndex = 3;
      this.showed = true;
    } 

  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    calculateRemainingaccountingFileSumDays() {
      // ------------------------------
      // ------------------------------
    },
    reinitForm() {
      this.$props.accountingfileData = this.defaultaccountingfileData;

      if (router.currentRoute.name == "accountingfile-add-new") {
        // init empty accountingfile data
        // accountingfileData = this.defaultaccountingfileData;
      } else {
        // requirement //
        // store
        //   .dispatch(APP_STORE_MODULE_NAME + "/fetchModule", {
        //     id: router.currentRoute.params.id,
        //   })
        //   .then((response) => {
        //     accountingfileData.value = response.data;
        //   })
        //   .catch((error) => {
        //     if (error.response.status === 404) {
        //       accountingfileData.value = undefined;
        //     }
        //   });
      }
      // remove all occurences
      // initialise 1 element
      // this.$props.accountingfileData.steps.push(
      //   JSON.parse(JSON.stringify(this.$props.stepformblankData))
      // );
    },
    async addUpdateItemAction() {
      // ----------------------------------------------------
      let formData = new FormData();
      const defaultActionText = this.actionName;

      let method = "";
      let params = "";
     
      if (router.currentRoute.name == "accountingfile-param") {
        this.actionName = "Parametrage en cours...";
        method = "put";
        params = "/" + this.accountingfileData.id;
      }

      // finalise form data
      formData = this.$props.accountingfileData;

      await myApi({
        url: "accountingfile" + params,
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Afficher Liste ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to list
                router.push({ name: "accountingfile-list" });
              }
            });
          } else {
            this.actionName = defaultActionText;
            this.errors.push(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          //
          this.actionName = defaultActionText;

          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    goToPrecItem() {
      router.push({ name: "accountingfile-view" });
    },
    goToNextItem() {
      router.push({ name: "accountingfile-list" });
    },
    goToList() {
      router.push({ name: "accountingfile-list" });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // accountingfile
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-accountingfile";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetaccountingfileData = () => {
      // this.accountingfileData = this.$props.accountingfileData;
    };

    let { refFormObserver, getValidationState, resetForm } = formValidation(
      resetaccountingfileData
    );

     const weekWorkTypeOption = [
      { label: "Anglaise (Lundi-Vendredi)", value: "Anglaise-UK-5" },
      { label: "Juive (Dimanche-Vendredi)", value: "Juive-IS-6" },
      { label: "Romaine (Lundi-Samedi)", value: "Romaine-JC-6" },
      { label: "Boudhiste (Vendredi-Mercredi)", value: "Boudhiste-BD-6" },
      { label: "Musulmane (Samedi-Jeudi)", value: "Musulmane-IS-6" },
      { label: "Complète (Dimanche-Samedi)", value: "Complète-AZ-7" },
    ];

    return {
      refFormObserver,
      getValidationState,
      weekWorkTypeOption,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .baccountingfile {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>